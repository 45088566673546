<template>
  <v-card color="grey lighten-4">
    <v-container fluid>
      <v-row dense class="title justify-end align-center">
        <v-btn
          color="color3"
          x-small
          text icon
          :loading="loading"
          @click.stop="loadLineups"
        >
          <v-icon>fas fa-sync-alt</v-icon>
        </v-btn>
      </v-row>
      <loading-bar :value="loading"></loading-bar>
      <v-row dense>
        <v-col cols="12">
          <v-window v-model="window" touchless>
            <v-window-item :value="0">
                <v-card-text class="text-center title">
                  <div>{{team.name}} has not submitted any lineups yet</div>
                </v-card-text>
            </v-window-item>
            <v-window-item :value="1">
              <v-row dense>
                <v-col cols="12" sm="6" v-for="(lineup, i) in lineups" :key="`${lineup.id}-${i}`">
                  <v-card
                    color="grey lighten-4"
                    class="ma-1"
                    height="100%"
                  >
                    <lineup-details
                      :lineup="lineup"
                      :division="division"
                      :team="team"
                      @cancel-click="onCancelClick"
                      :tokenStatus="tokenStatus"
                    ></lineup-details>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="false">
                  <v-card
                    color="grey lighten-2"
                    class="ma-1 d-flex align-center justify-center"
                  >
                  <v-btn color="color3" text @click.stop="newRoster">
                    <v-icon class="mr-3">fas fa-plus</v-icon>
                    Add a lineup
                  </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import LineupDetails from './PublicLineupDetails'
import Lineup from '@/classes/Lineup'

export default {
  props: ['team', 'tournament', 'division', 'selector', 'tokenStatus', 'value'],
  data () {
    return {
      window: 0,
      lineups: [],
      loading: false,
      selected: this.value,
      show: true
    }
  },
  computed: {
    selectedLineup () {
      return this.lineups && this.selected && this.lineups.find(f => f.id === this.selected)
    }
  },
  methods: {
    newRoster () {
      this.team && this.lineups.push(new Lineup({
        id: 0,
        teamId: this.team.id,
        name: `Line Up ${this.lineups.length + 1}`,
        type: this.division.props.find(f => f.startsWith('lineups-dual-'))
      }))
      this.window = 1
    },
    onCancelClick () {
      this.lineups = this.lineups.filter(f => f.id !== 0)
    },
    loadLineups () {
      this.loading = true
      this.$VBL.team.lineups.get(this.team.id)
        .then(r => {
          this.lineups = r.data.map(m => new Lineup(m))
          this.window = this.lineups.length === 0 ? 0 : 1
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    handleInput (e) {
      this.$emit('input', this.selected)
    }
  },
  mounted () {
    this.loadLineups()
  },
  watch: {
    'lineups.length': function (val) {
      if (!val) this.window = 0
    },
    selected: 'handleInput'
  },
  components: {
    LineupDetails
  }
}
</script>
