<template>
  <v-card class="fill-height flexcard">
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>{{lineup.name}} Lineup</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="grow">
      <v-row dense>
        <template>
          <v-col cols="12"
            v-for="(position) in lineup.positions" :key="position.n"
          >
            <lineup-pair :team="team" :position="position"></lineup-pair>
          </v-col>
        </template>
      </v-row>
      <v-expand-transition>
        <v-row v-if="availablePlayers.length > 0">
          <v-col cols="12">
            <v-card :flat="!editing" :outlined="!editing">
              <v-toolbar
                dense
                :color="editing ? 'color2 color2Text--text' : 'grey lighten-2 grey--text'"
                :flat="!editing"
              >
                <v-toolbar-title>
                  {{lineup.id > 0 ? 'Alternates' : 'Available Players'}}
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in availablePlayers"
                        :key="item.id"
                      >
                        <td>{{ item.name }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-expand-transition>
    </v-card-text>
    <v-card-actions class="justify-end shrink" v-if="showButtons">
      <v-fab-transition>
        <v-btn
          color="error"
          x-small text icon
          @click="confirmDelete = true"
          :disabled="loading"
          v-if="editing"
        >
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          color="error white--text"
          fab small
          @click="cancel"
          :disabled="loading"
          v-if="editing"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          :key="currentBtn.key"
          :color="currentBtn.color"
          :disabled="currentBtn.disabled"
          @click.stop="currentBtn.click"
          :loading="loading"
          fab small
        >
          <v-icon>{{currentBtn.icon}}</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>
    <v-dialog
      v-model="confirmDelete"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text class="text-center pa-3">
          <div class="title">Are you sure?</div>
          <div class="mb-3">Deleteing a lineup can not be undone</div>
          <v-btn color="success white--text" class="mr-3" @click="doDelete" :loading="loading">Yes</v-btn>
          <v-btn color="error white--text" @click="confirmDelete = false" :disabled="loading">No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Lineup from '@/classes/Lineup'
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'
import LineupPair from './LineupPair.vue'

export default {
  props: ['lineup', 'team', 'tokenStatus', 'disabled', 'hideName', 'showButtons', 'startInEdit'],
  data () {
    return {
      editable: null,
      editing: false,
      loading: false,
      confirmDelete: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    model () {
      return this.lineup
      // return this.editing ? this.editable : this.lineup
    },
    players () {
      return this.team.players
    },
    playersNeeded () {
      return this.lineup.playersNeeded
    },
    takenPlayers () {
      return this.model.players
    },
    availablePlayers () {
      return this.players.filter(f => !this.takenPlayers.includes(f.id))
    },
    valid () {
      return this.dirty // && this.editable.valid
    },
    currentBtn () {
      return this.editing ? {
        icon: 'fas fa-save',
        color: 'success white-text',
        disabled: !this.valid,
        key: 'save',
        click: this.save
      } : {
        icon: 'fas fa-pencil',
        color: 'color3Text color3--text',
        key: 'edit',
        click: this.edit
      }
    },
    dto () {
      return this.model && this.model.dto
    },
    oDto () {
      return this.lineup && this.lineup.dto
    },
    dirty () {
      return this.dto && (this.dto.id === 0 || JSON.stringify(this.dto) !== JSON.stringify(this.lineup.dto))
    },
    showSwapObj () {
      return this.model.positions.map(me => {
        if (me.n === this.model.positions.length) return false
        const before = this.model.positions.find(f => f.n === me.n - 1)
        const after = this.model.positions.find(f => f.n === me.n + 1)
        return me.players.length > 0 || (before && before.players.length > 0) || (after && after.players.length > 0)
      })
    }
  },
  methods: {
    swap (n) {
      const top = this.model.positions.find(f => f.n === n)
      const bottom = this.model.positions.find(f => f.n === n + 1)
      if (top && bottom) {
        const t = [...top.players]
        const b = [...bottom.players]
        top.players = [...b]
        bottom.players = [...t]
      }
    },
    edit () {
      this.editable = new Lineup(JSON.parse(JSON.stringify(this.lineup.dto)))
      this.editing = true
    },
    cancel () {
      this.$emit('cancel-click')
      this.editing = false
      this.editable = null
    },
    getAvailable (p) {
      return [...this.availablePlayers, ...this.players.filter(f => p.players.includes(f.id))].sort(firstBy('name'))
    },
    onChange (p) {
      if (p.players.length === 2) {
        const s = `select${p.n}`
        const ref = this.$refs[s]
        ref && ref[0] && ref[0].blur()
        const s2 = `select${p.n + 1}`
        const ref2 = this.$refs[s2]
        ref2 && ref2[0] && ref2[0].focus()
      }
    },
    save () {
      if (this.valid) {
        this.loading = true
        this.$VBL.team.lineups.post({ lineup: this.dto }, this.$route.query.token)
          .then(r => {
            this.lineup.update(r.data)
            this.cancel()
          })
          .catch(e => console.log(e))
          .finally(() => { this.loading = false })
      }
    },
    doDelete () {
      this.loading = true
      this.$VBL.team.lineups.delete({ lineup: this.lineup }, this.$route.query.token)
        .then(r => {
          this.lineup.id = 0
          this.cancel()
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    }
  },
  components: {
    LineupPair
  }
}
</script>
