<template>
  <v-card class="pa-2">
    <v-row dense>
      <v-col cols="12">Pair {{position.n}}</v-col>
      <v-col cols="6" v-for="player in players" :key="player.id">
        <v-card shaped>
          <v-avatar
            v-if="!noAvatar"
            :size="xs ? 40 : 60"
          >
            <img :src="player.profilePic" :alt="player.name">
          </v-avatar>
          {{player.name}}
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ['team', 'position', 'noAvatar'],
  computed: {
    xs () {
      return this.$vuetify.breakpoint.xsOnly || this.forceXs
    },
    players () {
      return this.team.players.filter(p => this.position.players.includes(p.id))
    }
  }
}
</script>
